
import Vue from "vue";

import {
  userMapActions,
  userMapMutations,
  userMapGetters,
  userMapState
} from "@/store/modules/user";
import UserEditFields from "@/components/User/UserEditFields.vue";
import Password from "@/components/User/Password.vue";
import { isExemptedUserRole, objectDifference } from "@/helpers";
import { getUserDefaults } from "@/helpers/defaultObjectGenerators";
import { agentCreate } from "@/forms/agent.create";
import { adminUser } from "@/forms/admin/user";

import { FormBlock } from "@/components/FormBuilder/types";
import { getFieldsFromBlocks } from "@/helpers/validateSchema";
import { get, omit } from "lodash";
import { IUserRoleStrings } from "@/types";
import { stripAgencyCode } from "@/helpers/generalHelpers";
import { AGENCY_UPDATED_EMAIL } from "@/helpers/constants";
import { IAdminUserData } from "@/store/modules/user/types";

interface IAddNewUserData {
  error: string;
  message: string;
  isAdmin: boolean;
  formIsValid: boolean;
  customKey: number;
  passwordIsValid: boolean;
  disableAgencyField: boolean;
  loadingText: string;
}

export default Vue.extend({
  name: "AddNewUser",
  components: {
    UserEditFields,
    Password
  },
  data(): IAddNewUserData {
    return {
      error: "",
      message: "",
      isAdmin: true,
      formIsValid: true,
      passwordIsValid: false,
      customKey: 0,
      disableAgencyField: false,
      loadingText: ""
    };
  },
  mounted() {
    const editingInitData: Record<string, any> = {};
    if (
      this.$getCurrentUser &&
      ["agencyAdmin", "subAdmin"].includes(this.$getCurrentUser?.role)
    ) {
      editingInitData.agencyId = this.$getCurrentUser.agencyId;
      // editingInitData.role = "agent";
      editingInitData.disabledPermissions = ["agent_reports:read"];
    }
    editingInitData.underWriterAgencies = [];
    this.SET_EDIT_USER(getUserDefaults(editingInitData));
  },
  destroyed() {
    this.SET_EDIT_USER("");
  },
  methods: {
    ...userMapActions(["createUser"]),
    ...userMapMutations(["SET_EDIT_USER"]),
    ...userMapMutations({
      editField: "SET_USER_EDIT_FIELD"
    }),
    async save(): Promise<void> {
      try {
        this.loadingText = "Saving user data. Please wait...";
        const newPassword = await (this as any).$refs.passwordComponent.save();
        this.updatedFields.password = newPassword;
        let updates: any = this.updatedFields;
        if (this.getEditingUser?.role !== "adjuster") {
          updates = omit(updates, [
            "mailingAddress",
            "physicalAddress",
            "isAtlasAdmin"
          ]);
        }
        const currentUserRole = get(this.$getCurrentUser, "role", "");
        if (!isExemptedUserRole(currentUserRole)) {
          updates = omit(updates, ["atlasAdmin"]);
        }
        await this.createUser(updates);
        if (this.initWithAgencyId) {
          this.$router.push({
            path: `/admin/agencies/${this.initWithAgencyId}/edit`
          });
        } else if (currentUserRole === "subAdmin") {
          this.$router.push(`/agencyAdmin/users`).catch(() => {});
        } else {
          this.$router.push(`/${currentUserRole}/users`).catch(() => {});
        }
      } catch (e) {
        this.$emit("scrollTop");
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.formIsValid = formIsValid;
    },

    disableAgencySelect() {
      this.disableAgencyField = true;
    },
    getStatus(role: IUserRoleStrings): "pendingTutorial" | "active" {
      return ["agent", "agencyAdmin", "subAdmin"].includes(role)
        ? "pendingTutorial"
        : "active";
    },
    checkTestAccountEmail() {
      if (
        this.getEditingUser?.email !== AGENCY_UPDATED_EMAIL &&
        this.getEditingUser?.agentCode &&
        stripAgencyCode(this.getEditingUser?.agentCode).toUpperCase() === "TMPW"
      ) {
        this.error = `User with agent code TMPW must have this email: ${AGENCY_UPDATED_EMAIL}`;
        return false;
      }

      return true;
    },
    adminUserData(disableAgencyField: boolean = false): IAdminUserData {
      return {
        showPhysicalAddress: this.showPhysicalAddress,
        showMailingAddress: this.showMailingAddress,
        agentSelected: this.agentSelected,
        disableAgencyField,
        role: this.getEditingUser?.role,
        agencySelected: this.getEditingUser?.agencyId,
        agentName: `${this.getEditingUser?.displayName}`
      };
    }
  },
  computed: {
    ...userMapState(["makingApiRequest"]),
    ...userMapGetters(["getEditingUser"]),
    disablePrimaryButton(): boolean {
      return !(
        this.formIsValid &&
        this.passwordIsValid &&
        this.checkTestAccountEmail()
      );
    },
    initWithAgencyId() {
      return this.$route.query.agencyId;
    },
    updatedFields(): any {
      const edited = this.getEditingUser;
      const original = getUserDefaults({});
      return edited && original ? objectDifference(edited, original) : {};
    },
    showPhysicalAddress(): boolean {
      if (
        this.getEditingUser &&
        this.getEditingUser.physicalAddress &&
        this.getEditingUser.physicalAddress.city !== "" &&
        this.getEditingUser.physicalAddress.houseNumber !== undefined &&
        this.getEditingUser.physicalAddress.streetName !== "" &&
        this.getEditingUser.role === "adjuster"
      ) {
        return true;
      } else {
        return false;
      }
    },
    showMailingAddress(): boolean {
      if (
        this.getEditingUser &&
        this.getEditingUser.mailingAddress &&
        this.getEditingUser.mailingAddress.city !== "" &&
        this.getEditingUser.mailingAddress.houseNumber !== undefined &&
        this.getEditingUser.mailingAddress.streetName !== "" &&
        this.getEditingUser.role === "adjuster"
      ) {
        return true;
      } else {
        return false;
      }
    },
    agentSelected(): boolean {
      return this.getEditingUser?.role == "agent" ? true : false;
    },
    addNewUserFields(): FormBlock[] {
      let user: any = {};
      const rolesToAllowDisableButton = ["agencyAdmin"].includes(
        this.$getCurrentUser?.role
      );
      if (
        this.$isCurrentUserAdmin ||
        this.$isCurrentUserAgencyAdmin ||
        this.$isCurrentUserSubAdmin
      ) {
        user = adminUser(this.adminUserData(rolesToAllowDisableButton));
      } else {
        user = agentCreate;
      }
      return user;
    }
  },
  watch: {
    "getEditingUser.role": {
      handler(newRole: IUserRoleStrings) {
        const formFields = getFieldsFromBlocks(adminUser(this.adminUserData()));
        const agentCode = formFields.find(
          (field: any) => field.id === "field_agentCode"
        );

        this.editField({
          key: "status",
          value: this.getStatus(newRole)
        });
        if (agentCode) {
          if (newRole === "admin") {
            agentCode.properties.required = false;
          } else {
            agentCode.properties.required = true;
          }
          this.customKey = this.customKey + 1;
        }
      },
      deep: true
    }
  }
});
